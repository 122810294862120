<template>
  <div class="container">
    <h1 class="container__titulo">O acesso ao Tagnos agora mudou,</h1>
    <h1 class="container__titulo">selecione o seu estado para continuar:</h1>
    <div class="container__svg">
      <svg
        version="1.1"
        id="svg-map"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="450px"
        height="460px"
        viewBox="0 0 450 460"
        enable-background="new 0 0 450 460"
        xml:space="preserve"
      >
        <g
          v-for="grafEstado, indexGrafEstado in grafEstados"
          :key="indexGrafEstado"
        >
          <a :href="getUrl(grafEstado.href)">
            <path 
              stroke="#FFFFFF"
              stroke-width="1.0404"
              stroke-linecap="round"
              stroke-linejoin="round"
              :d="grafEstado.d1"
            />
            <path
              v-if="grafEstado.d2"
              :d="grafEstado.d2"
            />
            <text :transform="grafEstado.textTransfor" fill="#FFFFFF">{{ grafEstado.estado }}</text>
          </a>
        </g>
      </svg>
    </div>
  </div>
  <footer class="footer">  
    <img class="footer__logo-tdr" src="../../public/logoTDRnovo.png" alt="Logo TDR">
    <img class="footer__logo-educacao " src="../../public/logorTagnosnovo.png" alt="Logo Educação">
  </footer>
</template>

<script>

import grafEstados from "../mocks/GrafEstados.json"
export default{
  name: 'InicioEducacao',
  props: {
  },
  data () {
    return {
      grafEstados: grafEstados,
      locationProtocol: ''
    }
  },
  created () {
    this.locationProtocol = window.location.protocol
    console.log(window.location.protocol)
  },
  methods: {
    getUrl (url) {
      return `${this.locationProtocol}${url}`
    }
  }
}
</script>

<style scoped>

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 100%;
  justify-content: center;
  height: 100vh;
}

.container__titulo {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  width: 95%;
}

@media (min-width:490px) {
  .container__titulo {
    font-size: 40px;
    width: 95%;
  }
}

@media (min-width:800px) {
  .container__titulo {
    font-size: 45px;
    font-weight: 600;
    width: 95%;
  }
}

#svg-map {
  height: 40vh;
  width: 100vw;
}

@media (min-width:490px) {
  #svg-map {
    height: 50vh;
    width: 80vw;
  }
}

@media (min-width:800px) {
  #svg-map {
    height: 70vh;
    width: 80vw;
  }
}

#svg-map path { 
  fill:#f1b77b 
}

#svg-map text { 
  fill:#fff; 
  font:12px Arial-BoldMT, sans-serif; 
  cursor:pointer 
}

#svg-map a { 
  text-decoration:none 
}

#svg-map a:hover { 
  cursor:pointer; 
  text-decoration:none 
}

#svg-map a:hover path{ 
  fill:#EB8217 !important 
}

#svg-map .circle {
  fill:#f1b77b
}

#svg-map a:hover .circle { 
  fill:#EB8217 !important; cursor:pointer 
}

.container__menssagem {
  text-align: center;
  color: #fff;
  font-size: 20px;
}

@media (min-width:490px) {
  .container__menssagem{
    text-align: center;
    color: #fff;
    font-size: 30px;
  }
}

.footer{
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.footer__logo-tdr {
  height: 24px;
  margin: 0px 10px;
  width: 56px;
}

@media (min-width:490px) {
  .footer__logo-tdr {
    height: 36px;
    width: 70px;
  }
}

.footer__logo-educacao {
  height: 32px;
  margin: 5px 10px;
  width: 126px;
}

@media (min-width:490px) {
  .footer__logo-educacao {
    height: 52px;
    width: 180px;
  }
}
</style>