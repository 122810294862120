<template>
  <inicioEducacao/>
</template>

<script>
import InicioEducacao from './components/InicioEducacao.vue'

export default {
  name: 'App',
  components: {
    InicioEducacao
  }
}
</script>

<style>

body {
  margin: 0;
  font-family: 'Roboto Slab', serif;
  padding: 0;
}

#app {
  background-image: url("background/tagnosWallpaper.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
